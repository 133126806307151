<template>
  <!-- <div> -->
  <!-- <Header></Header> -->
  <div class="content">
    <div class="w">
      <div class="title">
        <div class="titleanimate">
          <div class="titleone"><h2>学西语 ，不孤单。</h2></div>
          <div class="titletwo">
            <p class="dinRegular" style="margin-top: 10px">
              Con español, nada de soledad.
            </p>
          </div>
        </div>
      </div>
      <div class="search">
        <el-autocomplete
          popper-class="my-autocomplete"
          placeholder="请输入您要翻译的单词"
          v-model="keyword"
          class="input-with-select"
          :fetch-suggestions="querySearch"
          :trigger-on-focus="false"
          @select="pushSearch"
          :popper-append-to-body="false"
          @click.stop.native
          @keyup.enter.native="pushSearch()"
        >
          <el-select
            slot="prepend"
            v-model="select"
            placeholder="词典"
            :popper-append-to-body="false"
          >
            <el-option label="词典" value="1"></el-option>
            <el-option label="语料库" class="aftericon" value="2"
              >语料库<img
                class="aftericonimg"
                src="./images/diamond.png"
                alt=""
              />
            </el-option>
            <!-- 
            <el-option label="语料库" value="2">
              <span>{{ item.label }}</span>
              <i>{{ item.value }}</i>
            </el-option> -->
          </el-select>
          <el-button
            slot="append"
            icon="el-icon-search"
            @click.stop="pushSearch()"
          ></el-button>
          <template slot-scope="{ item }">
            <div class="wordlist" v-if="select == 1">
              <span
                class="word"
                v-html="brightenKeyword(item.word, keyword)"
              ></span
              ><span class="meaning"
                >&nbsp;&nbsp;&nbsp;&nbsp;{{ item.meaning }}</span
              >
            </div>
            <div class="wordlist" v-if="select == 2">
              <p class="word">
                <span></span
                ><span v-html="brightenKeyword(item.hit, keyword)"></span>
              </p>
              <p class="meaning">{{ item.targetContents }}</p>
            </div>
          </template>
        </el-autocomplete>
      </div>
      <div class="nav">
        <div class="navitem navitem1" @click="toJump('/search')">
          <img src="../../assets/img/home/search.png" alt="" />
          <h2>专业查词</h2>
          <p>
            中文单字准确全面的西班牙语释义、简单清晰的西西词典、工程贸易医学词汇查询、真人翻译语料，全部免费使用！
          </p>
        </div>
        <div class="navitem navitem2" @click="toJump('/course')">
          <img src="../../assets/img/home/course.png" alt="" />
          <h2>西语课程</h2>
          <p>
            零基础发音、语法、速成西班牙语基础到A2、名师语法讲解、经典视频分享全免费！
          </p>
        </div>
        <div class="navitem navitem3" @click="toJump('/find')">
          <img src="../../assets/img/home/find.png" alt="" />
          <h2>发现</h2>
          <p>
            少儿课程：自然拼读+RAZ系列阅读资料；海量西语影视剧、干货纪录片、西语短视频等；资料PDF观看或下载！
          </p>
        </div>
        <div class="navitem navitem4" @click="toJump('/partner')">
          <img src="../../assets/img/home/AI.png" alt="" />
          <h2>AI·语伴</h2>
          <p>
            我们购买全世界顶级人工智能接口，成本价提供给会员使用；其中写作文和翻译都可以让你获取最高端的使用体验！
          </p>
        </div>
      </div>
      <div class="saying" @click="copyeverydayword">
        <!-- <p>「一点浩然气，千里快载风。」</p>
        <p class="fontweight5 dinRegular" @click="speekkk">
          「Un poco de aire magnífico, miles de kilómetros de viento rápido.」
        </p> -->

        <p>「{{ everydayword.contentCh }}」</p>
        <p class="fontweight5 dinRegular">「{{ everydayword.contentSp }}」</p>
      </div>
    </div>
  </div>
</template>

<script>
// import "animate.css"
import {
  Search, searchCorpus, everydayList, spellError
} from "@/api"
export default {
  name: "Home",
  data () {
    return {
      keyword: '',
      select: '1',
      suggestions: [],
      synth: '',
      msg: '',
      voicesList: [],
      everydayword: {},
      SpeechSDK: {}
    }
  },
  mounted () {
    if (this.$route.params && this.$route.params.showLogin) {
      this.$bus.$emit('showLogin', this.$route.params)
      // this.$bus.$emit('showLoginDialog', this.$route.params.showLogin)
    }

    // console.log(process.env.VUE_APP_WX_APPID, 'process.env.VUE_APP_WX_APPID')

    // this.synth = window.speechSynthesis
    // this.msg = new SpeechSynthesisUtterance()
    // this.SpeechSDK = window.SpeechSDK
    // this.SpeechSDK = require("microsoft-cognitiveservices-speech-sdk")
    this.getEverydayList()
    // let teim = this.synth.getVoices()
    // this.voicesList = teim
    // console.log(this.synth.getVoices(), 'this.synth.getVoices()')
    // SpeechSynthesisUtterance.voice

  },
  methods: {
    copyeverydayword () {
      navigator.clipboard
        .writeText(this.everydayword.contentCh + this.everydayword.contentSp)
        .then(() => {
          this.$message.success("复制成功")
        })
        .catch((err) => {
          // 复制失败
          this.$message.error("复制失败")
        })
    },
    getEverydayList () {
      everydayList({ pageSize: 1, pageNum: 1 }).then((d) => {
        // console.log(d, 'xxxx')
        this.everydayword = d.rows[0] || {}

      })
    },
    speekkk () {
      // this.handleSpeak("Con español, nada de soledad.") // 传入需要播放的文字
      // console.log(window.speechSynthesis.getVoices())
      // this.readdanci("Un poco de aire magnífico, miles de kilómetros de viento rápido.")
    },
    // 语音播报的函数
    handleSpeak (text) {
      console.log(text, 'ttt')
      this.synth.cancel()
      this.msg.text = text     // 文字内容: 小朋友，你是否有很多问号
      this.msg.lang = "es-ES"  // 使用的语言:中文zh-CN
      // this.msg.lang = "zh-CN"
      // this.msg.voice = this.synth.getVoices().find((voice) => voice.lang === 'es-ES')
      this.msg.voiceURI = "Google español"
      // this.msg.name = "Google español"
      this.msg.volume = 1      // 声音音量：1
      this.msg.rate = 1        // 语速：1
      this.msg.pitch = 1

      this.msg.onend = () => {
        // 语音播放结束调用的方法，可以在里面写一些逻辑
        // this.stopVoice()
      }
      // 音高：1
      this.synth.speak(this.msg)    // 播放
    },

    // 停止播放
    stopVoice () {
      // this.msg.text = e
      // this.msg.lang = "zh-HK"
      synth.cancel(this.msg)
    },
    // speekkk () {

    //   var u = new SpeechSynthesisUtterance()
    //   u.text = "Un poco de aire magnífico, miles de kilómetros de viento rápido."
    //   u.lang = 'en-US'
    //   u.rate = 1
    //   u.onend = function (event) { console.log('Finished in ' + event.elapsedTime + ' seconds.') }
    //   window.speechSynthesis.cancel()
    //   speechSynthesis.speak(u)
    // },

    // speekkk () {
    //   console.log('xxxx')
    //   // let msg = new SpeechSynthesisUtterance("Un poco de aire magnífico, miles de kilómetros de viento rápido.")
    //   // msg.text = 
    //   // msg.lang = "en-GB"
    //   // window.speechSynthesis.speak(msg)
    //   // console.log(window.speechSynthesis.getVoices())
    //   const message = new SpeechSynthesisUtterance('你要播放的文字')
    //   window.speechSynthesis.cancel()
    //   window.speechSynthesis.speak(message)

    // },
    toJump (path) {
      this.$router.push({ path: path })
    },
    querySearch (queryString, cb) {
      // var restaurants = this.restaurants
      // var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      //keyword=ho&direction=es_to_ch&group=jt_total&pageNum=1&pageSize=15
      if (queryString) {
        if (this.select == 1) {
          Search({ keyword: queryString }).then((d) => {
            // console.log(d, 'ddd')
            this.suggestions = d.data
            if (d.data.length == 0) {
              spellError({ word: queryString }).then((d) => {
                this.suggestions = [{ isnet: true, word: '在线搜索', meaning: queryString }]
                this.suggestions = [...this.suggestions, ...d.data]
                // console.log(d.data, 'd.data')

                cb(this.suggestions)
              })

            } else {
              cb(this.suggestions)
            }
            // cb(this.suggestions)
          })
        } else if (this.select == 2) {  //语料搜索
          // searchCorpus({ keyword: queryString, direction: 'es_to_ch', group: 'jt_total', pageNum: 1, pageSize: 15 }).then((d) => {
          //   console.log(d, 'ddd')
          //   this.suggestions = d.data
          cb(this.suggestions)
          // })

        }

      }

      // 调用 callback 返回建议列表的数据

    },
    pushSearch (item) {
      // console.log(item, 'item')
      let isgoogle = false
      if (item && item.isnet) {
        this.keyword = item.meaning
        isgoogle = true
      } else if (item && item.word && this.select == 1) {
        this.keyword = item.word
      }
      // if (this.select == 1) this.keyword = item.word
      // console.log(this.keyword, item.word, 'item.word')
      this.$router.push({ name: 'Search', params: { word: this.keyword, select: this.select, isgoogle: isgoogle } })
    },
    brightenKeyword (val, keyword) {
      const Reg = new RegExp(keyword, "gi")
      // console.log(Reg)
      if (val) {
        return val.replace(
          Reg,
          `<span style="color: rgba(67, 207, 124, 1);
">${keyword}</span>`
        )
      }
    },

  }
}

</script>

<style lang="less" scoped>
@import url(../../assets/css/common.css);
/deep/.el-input__suffix {
  top: 2px;
}
/deep/.el-autocomplete-suggestion {
  // box-sizing: border-box;
  left: 0 !important;
  // top: 411px;
  width: 696px !important;
  height: 419px;
  opacity: 1;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 8px rgba(170, 170, 170, 0.25);
  text-align: left;
  overflow-x: hidden;
  padding: 0 20px;
}
/deep/.el-autocomplete-suggestion__wrap {
  max-height: 419px;
}
/deep/.el-autocomplete-suggestion li {
  padding: 10px;
}
/deep/.el-autocomplete-suggestion li:hover {
  width: 656px;
  // height: 50px;
  padding: 10px;
  opacity: 1;
  border-radius: 12px;
  background: rgba(245, 245, 247, 1);
}
/deep/.el-popper .popper__arrow {
  border: none !important;
}
/deep/.el-popper {
  margin-top: 6px;
}

/deep/.el-select-dropdown {
  width: 94px;
  height: 70px;
  opacity: 1;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 8px rgba(170, 170, 170, 0.25);
  margin-top: 38px;
}
/deep/.el-select-dropdown__item {
  width: 84px;
  height: 28px;
  padding: 0;
  line-height: 28px;
  opacity: 1;
  border-radius: 8px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 400;
  color: rgba(29, 29, 31, 1);

  // background: rgba(245, 245, 247, 1);
}
/deep/.el-popper .popper__arrow::after {
  content: none;
}
@keyframes siz {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes go {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes off {
  50% {
    border-right: 3px solid;
  }
}
.content {
  width: 100%;

  // margin-top: 180px;
  .pagew {
    max-width: 1500px;
  }
  .title {
    width: 100%;
    height: 260px;
    padding-top: 180px;
    h2 {
      white-space: nowrap;
      overflow: hidden;
      font-size: 36px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 47.74px;
      color: rgba(29, 29, 31, 1);
      // text-align: center;
      // vertical-align: top;
      // animation: siz 3s steps(8) forwards;
    }
    p {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 26.52px;
      color: rgba(29, 29, 31, 1);
      // text-align: center;
      // vertical-align: top;
    }
    .titleanimate {
      width: 301px;
      margin: 0 auto;

      .titleone {
        // width: 100%;
        border-color: transparent;
        // border-right: 3px solid;
        animation: go 3s steps(16), off 0.5s both step-end 6;
        animation-fill-mode: backwards;
      }
      .titletwo {
        // width: 0;
        // border-color: transparent;
        border-right: 3px solid transparent;
        animation: go 3s steps(22) 3s backwards,
          off 0.5s 3s step-end 6 alternate backwards;
      }
    }
  }
  .search {
    width: 100%;
    margin-top: 50px;
    text-align: center;
    /deep/.el-input-group {
      width: 696px;
      height: 90px;
      border-radius: 12px;
      background: rgba(255, 255, 255, 0.6);

      border: 2px solid rgba(255, 255, 255, 1);

      backdrop-filter: blur(20px);
    }
    /deep/.el-input__inner {
      width: 100%;
      height: 100%;
      border: none;
      background: rgba(255, 255, 255, 0.4);
      // backdrop-filter: blur(20px);

      font-size: 20px;
      font-weight: 400;
      letter-spacing: 0px;
      color: rgba(29, 29, 31, 1);
    }
    /deep/.el-input-group__append,
    .el-input-group__prepend {
      border: none;
      background: rgba(255, 255, 255, 0.4);
      // backdrop-filter: blur(20px);
    }

    /deep/.el-icon-search:before {
      content: "";
      display: block;
      width: 36px;
      height: 36px;
      background: url("../../assets/img/home/searchicon.png") no-repeat;
      background-size: 100%;
    }
    /deep/ .el-input-group__prepend {
      border: none;
      background-color: rgba(255, 255, 255, 0.4);
      // padding: 0 40px;
    }
    /deep/.el-icon-arrow-up:before {
      content: "";
      display: block;
      height: 17px;
      width: 19px;
      background: url("./images/down.png") no-repeat;
      background-size: 100% 100%;
      // background-position: 30px 0;
    }
    /deep/.el-select .el-input .el-select__caret {
      transform: none;
    }
    /deep/.el-select .el-input {
      width: 108px;
    }
    /deep/.el-select-dropdown__item.hover,
    .el-select-dropdown__item:hover {
      background: rgba(245, 245, 247, 1);
    }

    /deep/.el-input-group__prepend div.el-select .el-input__inner,
    .el-input-group__prepend div.el-select:hover .el-input__inner {
      color: #1d1d1f;
    }
    .aftericonimg {
      width: 24px;
      height: 24px;
      float: right;
      padding-top: 2px;
      padding-right: 4px;
    }
  }
  .nav {
    width: 100%;
    //height: 300px;
    margin-top: 120px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .navitem {
      width: 368px;
      height: 100%;
      padding: 27px 34px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      transition: all 0.5s linear;
      &:hover {
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.6);
        // background: 0.6;
      }

      img {
        width: auto;
        height: 100px;
      }
      h2 {
        font-size: 18px;
        font-weight: 500;
        line-height: 23.87px;
        color: rgba(29, 29, 31, 1);
        text-align: center;
        margin: 20px 0px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 24px;
        color: rgba(119, 119, 119, 1);
        text-align: center;
        vertical-align: top;
      }
    }
    // .navitem1 {
    //   img {
    //     width: 128px;
    //     height: 127px;
    //   }
    // }
    // .navitem2 {
    //   img {
    //     width: 128px;
    //     height: 121px;
    //   }
    // }
    // .navitem3 {
    //   img {
    //     width: 100px;
    //     height: 100px;
    //   }
    // }
    // .navitem4 {
    //   img {
    //     width: 112px;
    //     height: 109px;
    //   }
    // }
  }
  .saying {
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 2;
    color: rgba(29, 29, 31, 1);
    text-align: center;
    margin: 45px 0 0;
    padding-bottom: 140px;
    cursor: default;
  }
}
</style>